.orders {
    flex: 4;
	padding: 50px;
}

.icon {
    color: black;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;
}

@media (min-width: 720px) {
    
}