.invoice {
    padding: 50px;
    margin: 50px;

    /* Because of the footer */
    margin-bottom: 45rem;
}

input {
    border: none;
    height: 30px;
    border-bottom: 1px solid gray;
}

textarea {
    border: none;
    width: 250px;
    height: 30px;
    border-bottom: 1px solid gray;
}

textarea:focus {
    outline: none;
}

input:focus {
    outline: none;
}

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 25px;
    margin-top: 100px;
    width: auto;
}

.wrapperRow {
    display: flex;
}

.flexChild {
    flex: 1;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 25px;
}

.datePicker {
    width: 40%;
}

.right>input {
    width: 40%;
}

.note>textarea{
    min-width: 200px;
    min-height: 100px;
}

.invoiceFooter {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.left {
    text-align: left;
}
.total {
    font-weight: 600;
}

th {
    text-align: left;
    font-weight: 600;
    padding-bottom: 20px;
}

input:disabled {
    background-color: transparent;
}

.buttonWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 50px;
    gap: 10px;
}

.spanError{
    align-self: flex-start;
    border-left: 4px solid lightblue;
    background-color: aliceblue;
    padding: 10px;
    width: 98%;
    display: none;
}

.active {
    display: block;
}

.print {
    width: 200px;
}

.testTable {
    margin-top: 100px;
    width: 100%;
}

.alignLeft {
    text-align: left;
    border: 1px solid;
}

.alignRight {
    text-align: right;
    border: 1px solid;
}

.red {
    border-left: 4px solid red;
    background-color: lightcoral;
}

.deleteIcon {
    color: crimson;
}

.listRow {
    display: flex;
    justify-content: center;
    align-items: center;
}