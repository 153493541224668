.userList {
    flex: 4;
    margin: 30px;
    margin-left: 30px;
    margin-bottom: 35rem;
}

.userListUser {
    display: flex;
    align-items: center;
}

.userListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.userListEdit {
    margin-top: 5px;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    cursor: pointer;
    margin-right: 20px;
}

.userListDelete {
    margin-top: 5px;
    color: darkred;
    cursor: pointer;
}

.userAddButton {
    margin: 30px;
}

.addButtonLink {
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
}

.usernameAvatar {
    display: flex;
    align-items: center;
}

.usernameAvatar>span {
    margin-right: 5px;
}