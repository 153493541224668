.user {
    flex: 4;
    padding: 50px;
}

.userTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userContainer {
    display: flex;
    flex-direction: column;
    /* margin-top: 20px; */
}

.userShow {
    flex: 1;
    padding: 20px;
    -webkit-box-shadow: 5px 5px 15px -7px #000000;
	box-shadow: 5px 5px 15px -7px #000000;
}

.userShowTop {
    display: flex;
    align-items: center;
}

.userShowImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.userShowTopTitle {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.userShowUsername {
    font-weight: 600;
}

.userShowUserTitle {
    font-weight: 300;
}

.userShowTitle {
    font-size: 14px;
    font-weight: 600;
    color: rgb(156, 156, 156);
}

.userShowBottom {
    margin-top: 20px;
}

.userShowInfo {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}

.userShowIcon {
    font-size: 16px !important;
}

.userShowInfoTitle {
    margin-left: 10px;
}

.imagePreview {
    width: 400px;
    height: 400px;
}

@media (min-width: 720px) {
    
    .userContainer {
        flex-direction: row;
    }

}