.chart {
    /* width: 90%; */
    height: 300px;
	margin: 20px;
	padding: 20px;
    padding-left: 0;
    padding-bottom: 60px;
	-webkit-box-shadow: 5px 5px 15px -7px #000000;
	box-shadow: 5px 5px 15px -7px #000000;
}

.chartTitle {
    margin-bottom: 20px;
    padding-left: 20px;
}

.loadingCharts {
    padding: 100px;
    margin-bottom: 30rem;
}

.responsiveContainer {
    width: 100%;
    height: 100%;
}