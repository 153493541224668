.userUpdate {
    flex: 2;
    padding: 20px;
    -webkit-box-shadow: 5px 5px 15px -7px #000000;
	box-shadow: 5px 5px 15px -7px #000000;
}
.userUpdateImg {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}

.userUpdateForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
}

.userUpdateTitle {
    font-size: 24px;
    font-weight: 600;
}

.userUpdateItem {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.userUpdateItem>label {
    margin-bottom: 5px;
    font-size: 14px;
}

.userUpdateInput {
    border: none;
    width: 250px;
    height: 30px;
    border-bottom: 1px solid gray;
}

input:focus {
    outline: none;
}

.userUpdateRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.userUpdateUpload {
    display: flex;
    align-items: center;
    justify-content: center;
}

.userUpdateButton {
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    background-color: darkblue;
    color: white;
    font-weight: 600;
}

.space {
    gap: 10px;
}

.space50 {
    gap: 50px;
}

@media (min-width: 720px) {

    .userUpdate {
        margin-left: 20px;
    }

    .userUpdateForm {
        flex-direction: row;
    }
}