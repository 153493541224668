.invoiceList {
    padding: 50px;
    margin-top: 80px;
}

.deleteIcon {
    color: red;
    cursor: pointer;
}

.searchIcon {
    cursor: pointer;
}

.createButton {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.action {
    margin-right: 20px;
}